import axios from "axios";
import { store } from "../store";
import { setError } from "../OSCI/src/slice/errorModalSlice";
import i18n from "../i18n";
//process.env variable to be added
//apiconfig/ apiconstants to be added
const api = axios.create({
  baseURL: process.env.REACT_APP_TG_API_BASE_URL,
});

// Request interceptor
api.interceptors.request.use(
  (config: any) => {
    // Modify the request config here (e.g., add headers, authentication tokens)
    //const accessToken:any = JSON.parse(sessionStorage.getItem("token") || '{}') ;
    // ** If token is present add it to request's Authorization Header
    // if (Object.keys(accessToken).length > 0) {
    //   if (config.headers) {
    //     config.headers.token = `Bearer ${accessToken}`;
    //   }
    // }
    if (config?.loader) {
      document.documentElement.style.setProperty("--loading-spinner", "block");
    }
    if (config.headers) {
      config.headers.source = `website`;
      config.headers.hostName = window.location.origin
    }
    const authToken =
      typeof window !== "undefined" &&
      window?.sessionStorage?.getItem("accesstoken");
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const checkInAuthToken =
      typeof window !== "undefined" &&
      window?.sessionStorage?.getItem("checkintoken");
    if (checkInAuthToken) {
      config.headers.AccessToken = checkInAuthToken;
    }
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)
    document.documentElement.style.setProperty("--loading-spinner", "none");
    return response;
  },
  (error) => {
    if (error?.config?.isGenericErr) {
      const errorStatus = error?.response?.status || error?.status;
      // Handle response errors here
      if (error.code === "ERR_NETWORK") {
        store.dispatch(
          setError({
            message: i18n.t("error_network_failed"),
            heading: "Network Error",
            buttonText: i18n.t("button_too_many_codes_ok"),
          })
        );
      } else if (errorStatus === 401) {
        // Handle unauthorised scenario
        store.dispatch(
          setError({
            message: i18n.t("error_unauthorised"),
            heading: "Unauthorised Access",
            buttonText: i18n.t("button_too_many_codes_ok"),
            errCode: 401,
          })
        );
      } else if (
        errorStatus === 500 ||
        errorStatus === 501 ||
        errorStatus === 502 ||
        errorStatus === 503 ||
        errorStatus === 504
      ) {
        store.dispatch(
          setError({
            message: i18n.t("error_request_failed"),
            heading: "Server Error",
            buttonText: i18n.t("button_too_many_codes_ok"),
          })
        );
      } else if (
        errorStatus === 403 ||
        errorStatus === 404 ||
        errorStatus === 405 ||
        errorStatus === 408 ||
        errorStatus === 429
      ) {
        store.dispatch(
          setError({
            message: i18n.t("error_request_failed"),
            heading: "Client Error",
            buttonText: i18n.t("button_too_many_codes_ok"),
          })
        );
      }
    }
    document.documentElement.style.setProperty("--loading-spinner", "none");
    return Promise.reject(error);
  }
);

export default api;
